import React, { createContext, useState, useEffect, useContext, ReactNode, useCallback, useMemo, useRef } from 'react'
import { User, Session } from '@supabase/supabase-js'
import { useSupabase } from './supabasedb'
import { useNavigate, useLocation } from 'react-router-dom'

interface AuthContextType {
  user: User | null
  loading: boolean
  error: Error | null
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { supabase } = useSupabase()
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const lastPathRef = useRef(location.pathname)

  const setupSession = useCallback(async () => {
    const lastRefresh = sessionStorage.getItem('lastAuthRefresh')
    const now = Date.now()
    if (lastRefresh && now - parseInt(lastRefresh) < 60000) { // 1 minute
      return // Skip refresh if less than 1 minute has passed
    }

    try {
      const { data: { session }, error } = await supabase.auth.getSession()
      if (error) throw error
      setUser(session?.user ?? null)
      
      if (session) {
        const timeUntilRefresh = (session.expires_at ?? 0) - 300 - Math.floor(now / 1000)
        setTimeout(() => setupSession(), Math.max(0, timeUntilRefresh * 1000))
        sessionStorage.setItem('lastAuthRefresh', now.toString())
      }
    } catch (err) {
      console.error('Error fetching session:', err)
      setError(err instanceof Error ? err : new Error('Unknown error occurred'))
      setUser(null)
    } finally {
      setLoading(false)
    }
  }, [supabase.auth])

  useEffect(() => {
    let isActive = true

    setupSession()

    const { data: listener } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (!isActive) return
      setUser(session?.user ?? null)
      if (event === 'SIGNED_IN' && location.pathname === '/login') {
        navigate('/')
      } else if (event === 'SIGNED_OUT') {
        navigate('/login')
      }
      setLoading(false)
    })

    return () => {
      isActive = false
      listener?.subscription.unsubscribe()
    }
  }, [supabase.auth, navigate, setupSession, location])

  useEffect(() => {
    if (location.pathname !== lastPathRef.current) {
      lastPathRef.current = location.pathname
    }
  }, [location])

  const value = useMemo(() => ({
    user,
    loading,
    error
  }), [user, loading, error])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}