import React, { useEffect } from 'react'
import { Link, Routes, Route, useNavigate, Navigate, useLocation } from 'react-router-dom'
import Dashboard from './Dashboard'
import Projects from './Projects'
import Tasks from './Tasks'
import Cron from './Cron'
import Prompts from './Prompts'
import Entities from './Entities'
import Conversations from './Conversations'
import Conversation from './Conversation'
import Brainstorm from './BrainStorm'
import ToolManagement from './ToolManagement'
import FileUploadPage from './FileUploadPage'
import Voice from './Voice'
import Logout from '../components/system/Logout'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DashboardIcon from '@mui/icons-material/Dashboard'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import AssignmentIcon from '@mui/icons-material/Assignment'
import ScheduleIcon from '@mui/icons-material/Schedule'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import HubIcon from '@mui/icons-material/Hub'
import DvrIcon from '@mui/icons-material/Dvr'
import ChatIcon from '@mui/icons-material/Chat'
import UploadIcon from '@mui/icons-material/Upload'
import LogoutIcon from '@mui/icons-material/ExitToApp'
import Container from '@mui/material/Container'
import { useAuth } from '../contexts/AuthContext'
import Login from '../components/auth/Login'
import Signup from '../components/auth/Signup'
import brainSteamLogo from '../assets/img/brainsteam-logo-hor.png';
import ImageGen from './ImageGen'
import ImageIcon from '@mui/icons-material/Image'
import useIsLargeScreen from '../hooks/useIsLargeScreen'
import { styled, useTheme } from '@mui/material/styles';
import GuidedConversation from './GuidedConversation' // Add this import
import AssistantIcon from '@mui/icons-material/Assistant' // Add this import for the icon
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import BraveSearchToolSettings from '../components/BraveSearchToolSettings';
import BraveSearchTest from '../components/BraveSearchTest'; // Add this import
import SearchIcon from '@mui/icons-material/Search'; // Add this import for the search icon
import VoiceIcon from '@mui/icons-material/Mic'; // Add this import for the voice icon
import AMMI from './AMMI'; // Add this import
import PsychologyIcon from '@mui/icons-material/Psychology'; // Import an icon for AMMI

const drawerWidth = 240; // Reduced from 300 for a more compact expanded state

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    top: ['48px', '56px', '66px'],
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${(theme.mixins.toolbar[theme.breakpoints.up('sm')] as any).minHeight}px)`,
    },
    boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.1)',
    elevation: 1,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6), // Reduce from 7 to 6 for a slightly narrower collapsed state
    '&:hover': {
      width: drawerWidth,
    },
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
}));

const Layout: React.FC = React.memo(() => {
  const location = useLocation()

  useEffect(() => {
    console.log('Layout mounted')
    return () => console.log('Layout unmounted')
  }, [])

  useEffect(() => {
    console.log('Current path:', location.pathname)
  }, [location])

  console.log('Layout rendered')

  const theme = useTheme();
  const isLargeScreen = useIsLargeScreen()
  // Remove the open state and setOpen function as they're no longer needed
  // const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const { user, loading } = useAuth()

  // Remove the handleDrawerClose function as it's no longer needed
  // const handleDrawerClose = () => {
  //   setOpen(false)
  // }

  const handleNavigation = (path: string) => {
    navigate(path)
  }

  const navigationItems = [
    { name: 'Dashboard', path: '/', icon: <DashboardIcon /> },
    { name: 'Projects', path: '/projects', icon: <AccountTreeIcon /> },
    { name: 'Tasks', path: '/tasks', icon: <AssignmentIcon /> },
    { name: 'Cron', path: '/cron', icon: <ScheduleIcon /> },
    { name: 'Prompts', path: '/prompts', icon: <QuestionAnswerIcon /> },
    { name: 'Entities', path: '/entities', icon: <HubIcon /> },
    { name: 'Tools', path: '/tools', icon: <DvrIcon /> },
    { name: 'Conversations', path: '/conversations', icon: <ChatIcon /> },
    { name: 'Guided Conversation', path: '/guided-conversation', icon: <AssistantIcon /> }, // Add this line
    { name: 'Brainstorm', path: '/brainstorm', icon: <QuestionAnswerIcon /> },
    { name: 'Upload', path: '/file-upload', icon: <UploadIcon/>},
    { name: 'Image Gen', path: '/image-gen', icon: <ImageIcon />},
    { name: 'Logout', path: '/logout', icon: <LogoutIcon /> },
    { name: 'Brave Search Test', path: '/brave-search-test', icon: <SearchIcon /> }, // Add this line
    { name: 'Voice', path: '/voice', icon: <VoiceIcon /> }, // Add this line
    { name: 'AMMI', path: '/ammi', icon: <PsychologyIcon /> }, // Add this line
  ]

  if (loading) {
    return <div>Loading...</div>
  }

  if (!user) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ mt: 8 }}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </Box>
      </Container>
    )
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
        <AppBar position="fixed" sx={{ backgroundColor: 'white' }}>
          <Toolbar>
            {/* Remove the IconButton for the burger menu */}
            {isLargeScreen && (
              <Typography variant="h6" noWrap component="div" sx={{ mt:1 }}>
                <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <img src={brainSteamLogo} alt="BrainSteam Logo" style={{ maxWidth: '140px' }} />
                </Link>
              </Typography>
            )}
          </Toolbar>
        </AppBar>
        <StyledDrawer
          variant="permanent"
          anchor="left"
          open={true}
        >
          <List sx={{ flexGrow: 1, pt: 0 }}>
            {navigationItems.map((item) => (
              <Tooltip
                key={item.name}
                title={item.name}
                placement="right"
                TransitionComponent={Zoom}
              >
                <ListItem
                  button
                  onClick={() => handleNavigation(item.path)}
                  sx={{
                    minHeight: 40, // Reduce from 48 to 40 for less space between items
                    justifyContent: 'flex-start',
                    px: 2,
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 2, // Reduce from 3 to 2
                      justifyContent: 'center',
                      '& .MuiSvgIcon-root': {
                        fontSize: '1.2rem', // Make icons slightly smaller
                      },
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{
                      noWrap: true, // Prevent text wrapping
                      fontSize: '0.875rem', // Slightly smaller font size
                    }}
                    sx={{
                      opacity: 0,
                      transition: 'opacity 0.3s',
                      '.MuiDrawer-paper:hover &': {
                        opacity: 1,
                      },
                    }}
                  />
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </StyledDrawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            mt: ['48px', '56px', '64px'],
            ml: `${theme.spacing(6)}`, // Adjust from 7 to 6 to match the new collapsed drawer width
            transition: theme.transitions.create(['margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.standard,
            }),
          }}
        >
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/tasks" element={<Tasks />} />
            <Route path="/cron" element={<Cron />} />
            <Route path="/prompts" element={<Prompts />} />
            <Route path="/entities" element={<Entities />} />
            <Route path="/file-upload" element={
              <React.Suspense fallback={<div>Loading...</div>}>
                <FileUploadPage />
              </React.Suspense>
            } />
            <Route path="/voice" element={<Voice />} />
            <Route path="/conversations" element={<Conversations />} />
            <Route path="/conversation/:id" element={<Conversation />} />
            <Route path="/guided-conversation" element={<GuidedConversation />} /> {/* Add this line */}
            <Route path="/guided-conversation/:id" element={<GuidedConversation />} /> {/* Add this line */}
            <Route path="/brainstorm" element={<Brainstorm />} />
            <Route path="/image-gen" element={<ImageGen />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/tools" element={<ToolManagement />} />
            <Route path="/brave-search-settings" element={<BraveSearchToolSettings />} />
            <Route path="/brave-search-test" element={<BraveSearchTest />} /> {/* Add this line */}
            <Route path="/ammi" element={<AMMI />} /> {/* Add this line */}
          </Routes>
        </Box>
      </Box>
    </Container>
  )
})

export default Layout