import { useApi } from '../contexts/ApiContext';
import { useCallback, useState } from 'react';

interface VoyageEmbeddingResponse {
  data: Array<{
    embedding: number[];
    index: number;
  }>;
  model: string;
  usage: {
    prompt_tokens: number;
    total_tokens: number;
  };
}

export function useGenerateEmbedding() {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const generateEmbedding = useCallback(async (text: string): Promise<number[] | null> => {
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await api.post<VoyageEmbeddingResponse>('/api/embedding', { text });
      
      if (!response?.data?.[0]?.embedding) {
        const errorMsg = 'Invalid response format from Voyage API';
        console.error(errorMsg, response);
        setError(errorMsg);
        return null;
      }

      return response.data[0].embedding;
    } catch (error) {
      const errorMsg = error instanceof Error ? error.message : 'Error generating embedding';
      console.error('Error generating embedding:', error);
      setError(errorMsg);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  return { generateEmbedding, isLoading, error };
}