import React, { useState, MouseEvent } from 'react';
import { Box, Typography, Paper, styled, IconButton, InputBase, List, ListItem, ListItemText, useTheme, Chip, Avatar, CircularProgress } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import ListIcon from '@mui/icons-material/List';
import SearchIcon from '@mui/icons-material/Search';

// Remove the Tldraw import
// import { Tldraw } from 'tldraw'
// import 'tldraw/tldraw.css'

const Container = styled(Box)({
  display: 'flex',
  height: 'calc(100vh - 180px)',
  padding: '20px',
  gap: '20px',
});

const ChatAndCanvasArea = styled(Box)({
  flex: 1,
  display: 'flex',
  gap: '20px',
});

const ChatArea = styled(Paper)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
  background: '#1a1a1a',
  color: 'white',
}));

const CanvasArea = styled(Paper)(({ theme }) => ({
  flex: 2,
  borderRadius: '0px',
  overflow: 'hidden',
  boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
  background: '#1a1a1a',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const MessageList = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  padding: '20px',
});

const InputArea = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
  borderTop: '1px solid #333',
});

const StyledInputBase = styled(InputBase)({
  flex: 1,
  color: 'white',
  marginLeft: '10px',
});

const JobPanel = styled(Paper)(({ theme }) => ({
  width: '260px',
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
  background: '#1a1a1a',
  color: 'white',
}));

const JobList = styled(List)({
  padding: 0,
});

const JobItem = styled(ListItem)({
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
});

interface Message {
  id: string;
  content: string;
  sender: 'user' | 'ai';
}

interface Job {
  id: string;
  title: string;
  status: 'pending' | 'in_progress' | 'completed' | 'needs_input';
}

// Add a new styled component for the SVG
const StyledSvg = styled('svg')({
  width: '100%',
  height: '100%',
});

export function AMMI() {
  const theme = useTheme();
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [jobs, setJobs] = useState<Job[]>([
    { id: '1', title: 'Image Generation', status: 'in_progress' },
    { id: '2', title: 'Text Analysis', status: 'pending' },
    { id: '3', title: 'Data Processing', status: 'needs_input' },
  ]);

  const handleSendMessage = () => {
    if (inputValue.trim()) {
      const newMessage: Message = {
        id: Date.now().toString(),
        content: inputValue,
        sender: 'user',
      };
      setMessages([...messages, newMessage]);
      setInputValue('');
      // Here you would typically send the message to your backend/LLM
      // and handle the response
    }
  };

  const handleJobAction = (jobId: string) => {
    // Handle job actions (e.g., provide input, view results)
    console.log(`Action for job ${jobId}`);
  };

  // Add this new function to handle element clicks
  const handleElementClick = (elementId: string) => (event: MouseEvent<SVGGElement>) => {
    console.log(`Clicked element: ${elementId}`);
    // You can add more complex logic here, such as updating state or triggering other actions
  };

  return (
    <Container sx={{ maxWidth: '100%', flexGrow: 1 }}>
      <ChatAndCanvasArea>
        <ChatArea>
          <MessageList>
            {messages.map((message) => (
              <Box key={message.id} sx={{ display: 'flex', justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start', mb: 2 }}>
                <Chip
                  avatar={<Avatar>{message.sender === 'user' ? 'U' : 'AI'}</Avatar>}
                  label={message.content}
                  color={message.sender === 'user' ? 'primary' : 'secondary'}
                />
              </Box>
            ))}
          </MessageList>
          <InputArea>
            <StyledInputBase
              placeholder="Type your message..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            />
            <IconButton color="inherit" onClick={handleSendMessage}>
              <SendIcon />
            </IconButton>
          </InputArea>
        </ChatArea>
        <CanvasArea>
          <StyledSvg viewBox="0 0 500 300">
            <defs>
              <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3.5" orient="auto">
                <polygon points="0 0, 10 3.5, 0 7" fill="white" />
              </marker>
            </defs>

            {/* Element 1 */}
            <g onClick={handleElementClick('element1')} style={{ cursor: 'pointer' }}>
              <rect x="50" y="50" width="100" height="50" fill="#4a4a4a" rx="5" />
              <text x="100" y="80" textAnchor="middle" fill="white">Element 1</text>
            </g>

            {/* Element 2 */}
            <g onClick={handleElementClick('element2')} style={{ cursor: 'pointer' }}>
              <rect x="350" y="50" width="100" height="50" fill="#4a4a4a" rx="5" />
              <text x="400" y="80" textAnchor="middle" fill="white">Element 2</text>
            </g>

            {/* Arrow connecting Element 1 to Element 2 */}
            <line x1="150" y1="75" x2="350" y2="75" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" />

            {/* Element 3 */}
            <g onClick={handleElementClick('element3')} style={{ cursor: 'pointer' }}>
              <rect x="200" y="200" width="100" height="50" fill="#4a4a4a" rx="5" />
              <text x="250" y="230" textAnchor="middle" fill="white">Element 3</text>
            </g>

            {/* Arrows connecting to Element 3 */}
            <line x1="100" y1="100" x2="220" y2="200" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" />
            <line x1="400" y1="100" x2="280" y2="200" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" />
          </StyledSvg>
        </CanvasArea>
      </ChatAndCanvasArea>
      <JobPanel>
        <Box sx={{ p: 2, borderBottom: '1px solid #333' }}>
          <Typography variant="h6">Asynchronous Jobs</Typography>
        </Box>
        <JobList>
          {jobs.map((job) => (
            <JobItem key={job.id} onClick={() => handleJobAction(job.id)}>
              <ListItemText 
                primary={job.title}
                secondary={job.status}
                secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)' }}
              />
              {job.status === 'in_progress' && <CircularProgress size={20} />}
              {job.status === 'needs_input' && (
                <Chip label="Input Needed" color="warning" size="small" />
              )}
            </JobItem>
          ))}
        </JobList>
      </JobPanel>
    </Container>
  );
}

export default AMMI;