import { useApi } from '../contexts/ApiContext';
import { useCallback } from 'react';

interface ChatMessage {
  role: 'user' | 'assistant' | 'system';
  content: string;
}

export default function useChat() {
  const api = useApi();

  const sendMessage = useCallback(async (
    provider: string,
    messages: ChatMessage[],
    model: string,
    maxTokens: number
  ): Promise<string> => {
    try {
      const response = await api.post(`/api/${provider}/chat`, {
        messages,
        model,
        maxTokens
      });

      if (!response) {
        throw new Error('No response from API');
      }

      return response.content;
    } catch (error) {
      console.error(`Error in ${provider} chat:`, error);
      throw error;
    }
  }, [api]);

  const listModels = useCallback(async (provider: string): Promise<string[]> => {
    try {
      const response = await api.get(`/api/${provider}/models`);
      return response || [];
    } catch (error) {
      console.error(`Error fetching ${provider} models:`, error);
      throw error;
    }
  }, [api]);

  return {
    sendMessage,
    listModels,
    isLoading: false,
    error: null
  };
}